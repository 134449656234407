<template>
  <el-dialog
    :visible.sync="show"
    width="480px"
    top="14%"
    :close-on-click-modal="false"
    class="account-detail-dialog dialogform"
    :title="$t('dc:编辑账号')"
  >
    <el-form
      label-position="right"
      label-width="130px"
      :inline="false"
      hide-required-asterisk
      ref="formData"
      :model="formData"
    >
      <el-form-item :label="$t('dc:账号名称')" prop="accountName" class="">
        <el-input
          v-model="formData.accountName"
          maxlength="100"
          show-word-limit
          :disabled="true"
        ></el-input>
      </el-form-item>
      <el-form-item :label="$t('dc:邮箱')" prop="email" class="">
        <el-input v-model="formData.email" maxlength="100" show-word-limit :disabled="true">
        </el-input>
      </el-form-item>
      <el-form-item :label="$t('dc:代理商')" prop="agencyId">
        <el-select
          filterable
          v-model="formData.agencyId"
          :placeholder="$t('dc:请选择')"
          style="width: 100%"
        >
          <el-option
            v-for="(item, index) in agencyList"
            :key="index"
            :label="item.name"
            :value="item.id"
          ></el-option>
        </el-select>
      </el-form-item>
      <el-form-item :label="$t('dc:客户')" prop="clientId">
        <el-select
          filterable
          v-model="formData.clientId"
          :placeholder="$t('dc:请选择')"
          style="width: 100%"
        >
          <el-option
            v-for="(item, index) in clientList"
            :key="index"
            :label="item.name"
            :value="item.id"
          ></el-option>
        </el-select>
      </el-form-item>

      <el-form-item
        :label="$t('dc:密码')"
        prop="password"
        class=""
        v-if="formData.lionId === '' || formData.lionId === null"
      >
        <div style="display: flex; justify-content: space-between">
          <el-button style="flex: 1" @click="reset" class="passwordStatus">{{
            PasswordStatus
          }}</el-button>
          <div class="reset" :class="{ active: formData.haspassword }">
            <i class="el-icon-check"></i>
          </div>
        </div>
      </el-form-item>
    </el-form>

    <span slot="footer">
      <el-button
        v-if="formData.lionId === '' || formData.lionId === null"
        @click="submitForm('formData')"
        class="marginR20"
        >{{ $t('dc:发送') }}</el-button
      >
      <el-button type="primary" @click="save">{{ $t('dc:保存') }}</el-button>
    </span>
  </el-dialog>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  name: 'accountDialog',

  props: {
    visible: {
      type: Boolean,
      default: false,
    },
    type: String,
    typeOptions: {
      type: Array,
      default: () => [],
    },
    submitFromData: {
      type: Object,
    },
    max: Number,
  },
  data() {
    var accountNameRoute = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('请输入用户名'))
      } else if (!new RegExp('[A-Z]').test(value)) {
        callback(new Error(rule.message || '请输入正确的联系人'))
      } else {
        callback()
      }
    }
    var accountCodeRoute = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('请输入用户名'))
      } else if (!new RegExp('[A-Z]').test(value)) {
        callback(new Error(rule.message || '请输入正确的联系人'))
      } else {
        callback()
      }
    }
    return {
      show: this.visible,
      userAgencyList: [],
      passwordStatus: this.$t('dc:生成'),
      formData: this.submitFromData,
      rules: {
        productname: [{ required: true, message: this.$t('dc:请输入') }],
        accountName: [{ validator: accountNameRoute, trigger: ['blur', 'change'] }],
        module: [{ required: true, message: this.$t('dc:请输入') }],
        accountCode: [{ validator: accountCodeRoute, trigger: ['blur', 'change'] }],
      },
    }
  },
  computed: {
    ...mapGetters(['userInfo', 'agencyList', 'clientList']),
    HasLionId() {
      const lionId = this.userInfo.lionId
      if (this.userInfo.lionId !== '') return false
      else return true
    },
    PasswordStatus() {
      this.passwordStatus = this.$t('dc:生成')
      if (this.formData.accountStatus === 1) this.passwordStatus = this.$t('dc:生成')
      else this.passwordStatus = this.$t('dc:重置')
      return this.passwordStatus
    },
  },
  watch: {
    show(v) {
      this.$emit('update:visible', v)
    },
    formData(v) {
      this.formData = v
      if (v.accountStatus === 1) this.passwordStatus = this.$t('dc:生成')
      else this.passwordStatus = this.$t('dc:重置')
    },
    visible(v) {
      this.show = v
    },
  },
  created() {},
  methods: {
    async reset() {
      this.$set(this.formData, 'haspassword', false)
      const { status, data, msg } = await $Api.user.randomPwd(this.formData.userId)
      if (status === this.$RESPONSE_STATUS.SUCCESS) {
        this.$set(this.formData, 'haspassword', true)
        this.passwordStatus = this.$t('dc:重置')
        this.$message({
          showClose: true,
          message: msg || this.$t('dc:成功'),
          type: 'success',
        })
      }
    },
    onCancel() {
      this.show = false
    },
    async save() {
      if (!!this.formData.agencyId)
        this.formData.agency = this.agencyList.find((j) => j.id == this.formData.agencyId).name
      if (!!this.formData.clientId)
        this.formData.client = this.clientList.find((j) => j.id == this.formData.clientId).name
      const { status, msg } = await $Api.user.update(this.formData)
      if (status === this.$RESPONSE_STATUS.SUCCESS) {
        this.show = false
        this.$emit('queryApi')
        this.$message({
          showClose: true,
          message: msg || this.$t('dc:成功'),
          type: 'success',
        })
      }
    },

    submitForm(dataName) {
      this.$refs[dataName].validate((valid) => {
        if (valid) {
          this.approved()
        } else {
          return false
        }
      })
    },
    async approved() {
      const { status, msg } = await $Api.user.approvedEmail(this.formData)
      if (status === this.$RESPONSE_STATUS.SUCCESS) {
        this.show = false
        this.$message({
          showClose: true,
          message: msg,
          type: 'success',
        })
      }
    },
  },
}
</script>
<style lang="scss">
.account-detail-dialog {
  // .el-dialog .el-dialog__header {
  //   padding: 40px 40px 16px;
  // }
  .el-form-item__label {
    font-size: 14px;
  }
  .redFont .el-input__inner,
  .redFont .el-input__count-inner {
    color: red;
  }
  .el-checkbox__inner {
    width: 30px;
    height: 30px;
    text-align: center;
  }
  .passwordStatus {
    background: rgba(237, 252, 231, 1);
  }
  .reset {
    border-radius: 4px;
    display: block;
    width: 26px;
    height: 26px;
    margin-left: 16px;
    line-height: 26px;
    border: 1px solid #ccc;
    color: #ccc;
    font-size: 14px;
    text-align: center;
  }
  .reset.active {
    color: #fff;
    background: $--color-primary2;
    border-color: $--color-primary2;
  }
}
</style>
