<template>
  <div>
    <el-dialog
      :visible.sync="show"
      width="750px"
      top="10%"
      :close-on-click-modal="false"
      class="account-list infomation-dialog"
      title="Group Infomation"
    >
      <div class="cascaderBox">
        <div class="cascaderHeader">
          <ul>
            <li>{{ $t('dc:用户组名称') }}</li>
            <li>{{ $t('dc:产品名称') }}</li>
            <li>{{ $t('dc:权限名称') }}</li>
          </ul>
        </div>
        <el-cascader-panel
          v-if="showInfomationValue"
          style="width: 750px"
          v-model="infomationValue"
          :options="infomationOptions"
          :props="props"
        >
          <template slot-scope="{ node, data }">
            <el-tooltip v-if="node.level === 3" effect="dark" placement="bottom">
              <div slot="content">{{ data.name }}</div>
              <span>{{ data.name }}</span>
            </el-tooltip>
            <div v-else>
              <div slot="content">{{ data.name }}</div>
            </div>
          </template>
        </el-cascader-panel>
      </div>
    </el-dialog>
    <UsuallyDialog
      v-if="show4Dialog"
      :visible.sync="show4Dialog"
      :title="title"
      :className="classNameOne"
      :content="content"
      @confrom="confrom"
    ></UsuallyDialog>
  </div>
</template>

<script>
import config from '@/api/config'
import UsuallyDialog from '@/views/dialog/usuallyDialog'
import { mapGetters, mapActions } from 'vuex'

export default {
  name: 'infomationDialog',
  components: {
    UsuallyDialog,
  },
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
    rawData: {},
    type: String,
  },
  data() {
    return {
      loading: false,
      show: this.visible,
      show4Dialog: false,
      classNameOne: 'home',
      title: this.$t('dc:提醒'),
      content: this.$t('dc:你确认改变你的品牌？'),
      infomationOptions: [],
      props: {
        value: 'value', //设置每个menu的ID值
        label: 'name', //设置每个menu的name值
        children: 'children', //子级
      },
      showInfomationValue: false,
      infomationValue: [],
    }
  },

  watch: {
    show(v) {
      this.$emit('update:visible', v)
    },
    visible(v) {
      this.show = v
    },
  },
  computed: {
    ...mapGetters(['userInfo']),
  },
  mounted() {
    this.initEvent()
  },
  methods: {
    initEvent() {
      this.getAllAdminAccess({ userId: this.rawData.userId })
    },
    async getAllAdminAccess(params) {
      this.showInfomationValue = false
      let { status, data } = await $Api.user.getAllAccess(params)
      if (status === this.$RESPONSE_STATUS.SUCCESS) {
        this.infomationOptions = data
        if (data.length > 0) {
          this.infomationValue[0] = data[0].value
          this.infomationValue[1] = data[0].children[0].value
          this.infomationValue[2] = data[0].children[0].children[0].value
          this.showInfomationValue = true
        }
        // else {
        //   this.$message({
        //   showClose: true,
        //   message:  this.$t('dc:数据为空'),
        //   type: "warning"
        // });
        // }
      }
    },
    handleChange(value) {
      if (value.length > 0) {
        // 获取当前选中节点
        let checkNode = this.$refs['cascader'].getCheckedNodes()[0]
        if (checkNode.level == 1 && checkNode.parent == null) {
          this.label = checkNode.label
        } else if (checkNode.level == 2 && checkNode.parent.parent == null) {
          this.label = checkNode.parent.label + '-' + checkNode.label
        } else if (checkNode.level == 3 && checkNode.parent.parent != null) {
          this.label =
            checkNode.parent.parent.label + '-' + checkNode.parent.label + '-' + checkNode.label
        }
        this.code = checkNode.data.code
      }
    },

    onCancel() {
      this.show = false
    },
    submitForm(formName) {},
    confrom() {},
  },
}
</script>
<style lang="scss" scoped>
.marginB50 {
  margin-bottom: 50px !important;
}
.cascaderBox {
  margin-left: 0px;
}
.cascaderHeader ul {
  // width: 538px;
  height: 40px;
  line-height: 40px;
  margin: 0;
  display: flex;
  //   margin-right: -4px;
  //   margin-bottom: -2px;
  li {
    width: 196px;
    background: #f5f5f5;
    padding: 0 16px;
    font-size: 14px;
    font-weight: 600;
    color: #000;
  }
  li:nth-child(2) {
    // margin: 0 4px;
    border-left: 2px solid #fff;
    border-right: 2px solid #fff;
  }
  li:nth-child(3) {
    width: 258px;
  }
}
</style>
