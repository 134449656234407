<template>
  <div>
    <el-dialog
      :visible.sync="show"
      width="480px"
      top="15%"
      :title="titleMsg"
      :close-on-click-modal="false"
      class="acctount-detail-dialog"
    >
      <div>
        <el-tabs type="border-card" v-model="activeId" @tab-click="clickTab">
          <el-tab-pane :label="$t('dc:同意')" name="1">
            <el-select
              style="width: 260px; margin: 0 auto"
              v-model="emailParams.projectIdList"
              filterable
              multiple
              :placeholder="$t('dc:请选择')"
              @change="onChange"
              class="marginR24"
            >
              <el-option
                v-for="item in productList"
                :key="item.id"
                :label="item.name"
                :value="item.id"
              ></el-option> </el-select
          ></el-tab-pane>
          <el-tab-pane :label="$t('dc:不同意')" name="2">
            <el-select
              style="width: 260px; margin: 0 auto; margin-bottom: 10px"
              v-model="emailParams.projectIdList"
              filterable
              multiple
              :placeholder="$t('dc:请选择')"
              @change="onChange"
              class="marginR24"
            >
              <el-option
                v-for="item in productList"
                :key="item.id"
                :label="item.name"
                :value="item.id"
              ></el-option>
            </el-select>
            <el-input
              v-model="emailParams.reason"
              type="textarea"
              :maxRows="3"
              resize="none"
              show-word-limit
              style="width: 260px; margin: 0 auto"
              :placeholder="$t('dc:请输入拒绝理由')"
            ></el-input>
          </el-tab-pane>
        </el-tabs>
      </div>
      <div slot="footer">
        <!-- <el-button type @click="show=false" class="marginR20">{{$t('dc:否')}}</el-button> -->
        <el-button type="primary" @click="submit">{{ $t('dc:发送邮件') }}</el-button>
      </div>
    </el-dialog>
  </div>
</template>
<script>
export default {
  name: 'sendEmailDialog',
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
    rawData: Object,
    title: String,
    content: String,
  },
  watch: {
    show(v) {
      this.$emit('update:visible', v)
    },
    visible(v) {
      this.show = v
    },
    rawData(v) {},
  },
  data() {
    return {
      show: this.visible,
      rowJson: this.rawData,
      emailParams: {
        userId: this.rawData.userId,
        approveType: 1,
        projectIdList: [],
        reason: '',
      },
      productList: [],
      activeId: '1',
      titleMsg: this.title,
      contentMsg: this.content,
    }
  },
  mounted() {
    this.getRoleInfo()
  },
  methods: {
    async getRoleInfo() {
      let { status, data, msg } = await $Api.project.getRoleAccessInfo(this.rawData.userId)
      if (status === this.$RESPONSE_STATUS.SUCCESS) {
        this.productList = data
      }
    },
    onChange() {},
    clickTab() {
      switch (this.activeId) {
        case '1':
          this.emailParams.approveType = 1
          this.emailParams.reason = ''
          break
        case '2':
          this.emailParams.approveType = 2
          this.emailParams.projectIdList = []
          break
        default:
          break
      }
    },
    async submit() {
      if (this.emailParams.projectIdList.length === 0) {
        return this.$message({
          showClose: true,
          message: 'please select the project',
          type: 'warning',
        })
      }
      if (this.emailParams.reason === '' && this.activeId === '2') {
        return this.$message({
          showClose: true,
          message: 'please select the reason',
          type: 'warning',
        })
      }
      let { status } = await $Api.access.projectExamine(this.emailParams)
      if (status === this.$RESPONSE_STATUS.SUCCESS) {
        this.show = false
        this.$emit('confrom')
      }
    },
  },
}
</script>
<style lang="scss">
.acctount-detail-dialog .el-tabs__content {
  height: 100px;
}
</style>
