<template>
  <el-dialog
    :visible.sync="show"
    width="680px"
    top="14%"
    :close-on-click-modal="false"
    class="role-detail-dialog"
  >
    <div slot="title" class="header-box">
      <span class="rddd-title">{{ $t('dc:账户角色分配') }}</span>
    </div>
    <div class="header-box">
      <span>{{ $t('dc:权限名称') }}</span>
      <el-button class="btn-black" type="info" @click="addProduct">{{
        $t('dc:新增产品')
      }}</el-button>
    </div>
    <div class="descriptionBox">
      <span v-for="(item, index) in titleList" :key="index">{{ item.name }} </span>
    </div>
    <PocTable :data="tableData" max-height="454" hidepage class="border1">
      <el-table-column
        v-for="item in columnConfig"
        :key="item.key"
        :prop="item.key"
        :label="item.name"
        :width="item.width"
        show-overflow-tooltip
      >
        <template slot-scope="scope">
          <span v-if="item.key === 'AT'">
            <el-select
              v-model="scope.row.id"
              placeholder="please select product"
              style="width: 172px"
              @change="changeProductType(scope.row, scope.$index)"
            >
              <el-option
                v-for="items in productList"
                :key="items.id"
                :label="items.name"
                :value="items.id"
              >
              </el-option>
            </el-select>
          </span>
          <span v-else-if="item.key === 'AB'">
            <el-select
              v-model="scope.row.selectList"
              multiple
              placeholder="please select role"
              style="width: 100%"
              ref="selectRole"
              @change="changeRole"
            >
              <el-option
                v-for="items in scope.row.roleList"
                :key="items.id"
                :label="items.name"
                :value="items.id"
              >
              </el-option>
            </el-select>
          </span>
        </template>
      </el-table-column>
    </PocTable>
    <span slot="footer">
      <div style="text-align: right">
        <el-button class="" @click="onCancel">{{ $t('dc:取消') }}</el-button>
        <el-button class="btn-black" type="info" @click="innerVisible = true">{{
          $t('dc:确认')
        }}</el-button>
      </div>
    </span>
    <el-dialog
      width="30%"
      :visible.sync="innerVisible"
      append-to-body
      :close-on-click-modal="false"
      :show-close="false"
      title="Confirm"
    >
      <div>
        {{ content }}
      </div>
      <div slot="footer">
        <el-button class="" @click="innerVisible = false">{{ $t('dc:否') }}</el-button>
        <el-button class="btn-black" type="info" @click="submit">{{ $t('dc:是') }}</el-button>
      </div>
    </el-dialog>
  </el-dialog>
</template>

<script>
export default {
  name: 'roleAssignmentDialog',
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
    rowRolwData: {
      type: Object,
      default: {},
    },
  },

  data() {
    return {
      show: this.visible,
      innerVisible: false,
      content: 'Do you confirm the information of allocation?',
      columnConfig: [
        {
          name: 'Product Name',
          key: 'AT',
          width: 200,
        },
        {
          name: 'Role Name',
          key: 'AB',
          width: null,
        },
      ],
      rowJson: this.rowRolwData,
      productList: [],
      roleList: [],
      roleIdList: [],
      titleList: [],
      tableData: [
        {
          id: '',
          selectList: [],
          roleList: [],
        },
        {
          id: '',
          selectList: [],
          roleList: [],
        },
      ],
      assignRoleParams: {
        userId: this.rowRolwData.userId,
        roleIdList: [],
      },
      multipleSelection: [],
      value: '',
    }
  },

  watch: {
    show(v) {
      this.$emit('update:visible', v)
    },
    visible(v) {
      this.show = v
    },
    rowRolwData(v) {
      this.rowJson = v
    },
  },
  mounted() {
    this.initEvent()
  },
  methods: {
    async initEvent() {
      this.getProductList()
      this.getRoleInfo()
    },
    async getProductList() {
      let { status, data } = await $Api.project.optionList()
      if (status === this.$RESPONSE_STATUS.SUCCESS) {
        this.productList = data

        // this.$message({
        //   showClose: true,
        //   message: msg,
        //   type: "success"
        // });
      }
    },
    async getRoleInfo() {
      let { status, data, msg } = await $Api.project.getRoleAccessInfo(this.rowJson.userId)
      if (status === this.$RESPONSE_STATUS.SUCCESS) {
        this.tableData = data
        const newArrlist = []
        this.tableData.map((item, index) => {
          item.selectList.map((val) => {
            newArrlist.push(val)
          })
        })
        this.getRoleNameList(newArrlist)
      }
    },
    async getRoleList() {
      let { status, data, msg } = await $Api.role.getRoleOptionList()
      if (status === this.$RESPONSE_STATUS.SUCCESS) {
        this.roleList = data
      }
    },
    async changeProductType(row, index) {
      let { status, data } = await $Api.project.getProductMsg(row.id)
      if (status === this.$RESPONSE_STATUS.SUCCESS) {
        this.tableData[index].selectList = []
        this.tableData[index].roleList = data
      }
    },
    async changeRole(row, index) {
      const newArrlist = []
      this.tableData.map((item, index) => {
        item.selectList.map((val) => {
          newArrlist.push(val)
        })
      })
      this.$refs.selectRole[0].blur()
      this.getRoleNameList(newArrlist)
    },
    async getRoleNameList(list) {
      let { status, data } = await $Api.role.getRoleOptionList({ roleIdList: list })
      if (status === this.$RESPONSE_STATUS.SUCCESS) {
        this.titleList = data
      }
    },
    addProduct() {
      let newJson = {
        id: '',
        selectList: [],
        roleList: [],
        productList: this.productList,
      }
      this.tableData.push(newJson)
    },
    handleSelectionChange(val) {
      this.multipleSelection = val
    },

    async submit() {
      const newArrlist = []
      this.tableData.map((item, index) => {
        item.selectList.map((val) => {
          newArrlist.push(val)
        })
      })
      this.assignRoleParams.roleIdList = newArrlist
      let { status, msg } = await $Api.project.assignRole(this.assignRoleParams)
      if (status === this.$RESPONSE_STATUS.SUCCESS) {
        this.innerVisible = false
        this.show = false
        this.$message({
          showClose: true,
          message: msg || this.$t('dc:成功'),
          type: 'success',
        })
        this.$emit('queryApi')
      }
    },
    /**
     * 取消
     */
    onCancel() {
      this.show = false
    },
    /**
     * 下载
     */
    onDownload(row) {},
    pageSizeChange() {},
    pageChange() {},
  },
}
</script>

<style lang="scss">
.role-detail-dialog {
  .el-dialog .el-dialog__body {
    padding-top: 0;
  }
  .rddd-title {
    font-weight: 400;
  }
  .roleDetail-box {
    h3 {
      text-align: left;
      padding: 0 15px;
      margin: 0;
    }
    &-rawdata {
      padding: 0 15px;
      display: flex;
      span {
        flex: 1;
      }
    }
    &-select {
      padding: 0px 5px 15px;
      // text-align: center;
    }
  }
  .header-box {
    display: flex;
    align-items: center;
    justify-content: space-between;
    line-height: 28px;
    margin-bottom: 10px;
  }
  .descriptionBox {
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;
    height: 28px;
    font-size: 12px;
    line-height: 28px;
    margin-bottom: 16px;
    span {
      margin-right: 10px;
      font-weight: 400;
    }
  }
  .border1 {
    border: 1px solid #ebeef5;
  }
  .maringT0 {
    margin-top: 0;
  }
  .marginR30 {
    margin-right: 30px;
  }
}
</style>
