<template>
  <div class="authority common-page">
    <PocCardTable>
      <template slot="headerTitle">
        <span class="font21 colorB">{{ $t('dc:账号管理') }}</span>
        <span class="padRL6 font21">/</span>
        <span class="font21">{{ $t('dc:账号列表') }}</span>
      </template>
      <template slot="headerGroup">
        <el-input
          v-model="pageConfig.searchKey"
          :placeholder="$t('dc:请输入账号名称')"
          style="width: 240px; margin-right: 10px"
          :clearable="true"
          @keyup.enter.native="queryApi"
          @clear="queryApi"
        >
          <el-button
            class="btn-black"
            slot="append"
            @click="queryApi"
            icon="el-icon-search"
          ></el-button>
        </el-input>
        <el-button class="btn-black" type="info" @click="show4AddDialog = true">{{
          $t('dc:新增账号')
        }}</el-button>
      </template>
      <PocTable
        v-loading="loading"
        :data="tableData"
        :current-page.sync="pageConfig.pageNum"
        :total="pageConfig.total"
        :border="false"
        @size-change="pageSizeChange"
        @page-current-change="pageChange"
      >
        <el-table-column
          v-for="item in columnOptions"
          :key="item.key"
          :align="item.align"
          :prop="item.key"
          :label="item.name"
          :fixed="item.fixed"
          :sorter="item.sortable"
          :min-width="item.width"
          show-overflow-tooltip
        >
          <template slot-scope="scope">
            <span>{{ scope.row[item.key] || '-' }} </span>
          </template>
        </el-table-column>
        <el-table-column :label="$t('dc:操作')" width="180" fixed="right" align="center">
          <template slot-scope="scope">
            <div class="action-btn-group">
              <el-tooltip effect="dark" :content="$t('dc:编辑')" placement="top">
                <i
                  class="poc-icon-info poc-size12 iconfont icon-oneui-contract-edit padding12 nicon"
                  @click="accountEdit(scope.row)"
                ></i>
              </el-tooltip>
              <el-tooltip effect="dark" :content="$t('dc:用户组信息')" placement="top">
                <i
                  class="poc-icon-info poc-size12 iconfont icon-oneui-mdm-client padding12 nicon"
                  @click="showInformation(scope.row)"
                ></i>
              </el-tooltip>
              <el-tooltip
                effect="dark"
                :content="
                  scope.row.accountStatus === 0
                    ? $t('dc:禁用')
                    : scope.row.accountStatus === 3
                    ? $t('dc:恢复')
                    : $t('dc:驳回')
                "
                placement="top"
              >
                <i
                  v-if="scope.row.accountStatus === 0"
                  :class="'icon-oneui-contract-disable'"
                  class="poc-icon-info poc-size12 iconfont padding12 nicon"
                  @click="forbid(scope.row)"
                ></i>
                <i
                  v-if="scope.row.accountStatus === 1"
                  :class="'icon-oneui-contract-dismissed'"
                  class="poc-icon-info poc-size12 iconfont padding12 nicon"
                  @click="reject(scope.row)"
                ></i>
                <i
                  v-if="scope.row.accountStatus === 3"
                  :class="'icon-oneui-reload'"
                  class="poc-icon-info poc-size12 iconfont padding12 nicon"
                  @click="recover(scope.row)"
                ></i>
              </el-tooltip>

              <el-tooltip effect="dark" :content="$t('dc:删除')" placement="top">
                <i
                  class="poc-icon-info poc-size12 iconfont padding12 icon-oneui-delete nicon"
                  @click="deleteRow(scope.row)"
                ></i>
              </el-tooltip>
            </div>
          </template>
        </el-table-column>
      </PocTable>
    </PocCardTable>
    <accessDialog
      v-if="showDialog"
      :visible.sync="showDialog"
      :submitFromData="accountFromData"
      @queryApi="queryApi"
    ></accessDialog>
    <Forbid4Delete
      v-if="show4Dialog"
      :visible.sync="show4Dialog"
      :title="title"
      :content="content"
      @confrom="confrom"
    ></Forbid4Delete>
    <roleAssignmentDialog
      v-if="showAssignmentDialog"
      :visible.sync="showAssignmentDialog"
      :rowRolwData="rowRolwData"
      @queryApi="queryApi"
    ></roleAssignmentDialog>
    <sendEmaiDialog
      v-if="sendEmailStatus"
      :visible.sync="sendEmailStatus"
      :title="title"
      :rawData="senEmaiData"
    ></sendEmaiDialog>
    <addAccountDialog
      v-if="show4AddDialog"
      :visible.sync="show4AddDialog"
      :title="addAccountTitle"
      @queryApi="queryApi"
    ></addAccountDialog>
    <InformationDialog
      v-if="showInformationDialog"
      :visible.sync="showInformationDialog"
      :rawData="informationData"
      >></InformationDialog
    >
  </div>
</template>

<script>
import accessDialog from '@/views/dialog/accountDialog'
import Forbid4Delete from '@/views/dialog/usuallyDialog'
import roleAssignmentDialog from '@/views/dialog/roleAssignmentDialog'
import sendEmaiDialog from '@/views/dialog/sendEmailDialog'
import addAccountDialog from '@/views/dialog/addAccountDialog'
import InformationDialog from '@/views/dialog/groupInformationDialog'
export default {
  name: 'authority',
  mixins: [$PCommon.TableMixin],
  components: {
    accessDialog,
    Forbid4Delete,
    roleAssignmentDialog,
    sendEmaiDialog,
    addAccountDialog,
    InformationDialog,
  },
  data() {
    return {
      productName: '',
      pageConfig: {
        searchKey: '',
      },
      productOptions: [],
      title: this.$t('dc:禁止'),
      content: this.$t('dc:您确认要禁止该用户吗?'),
      addAccountTitle: this.$t('dc:新增账号'),
      show4Dialog: false,
      loading: false,
      showDialog: false,
      showAssignmentDialog: false,
      sendEmailStatus: false,
      show4AddDialog: false,
      showInformationDialog: false,
      tableData: [],
      rowRolwData: {},
      senEmaiData: {},
      accountFromData: {},
      accountData: {},
      informationData: {},
      actionType: 0,
      accountStatus: 0,
    }
  },
  watch: {
    showDialog(v) {
      this.initEvent()
    },
  },
  created() {},
  computed: {
    columnConfig() {
      return [
        {
          key: 'no',
          name: 'No.',
          align: 'left',
          dataType: 'string', // 字段的数值类型，字符串（string）、数字（number）、日期（date）、布尔（boolean）。
          visible: false, // 是否展示在表格列中
          sortable: false, // 是否可以排序
          fixed: false, // 固定列的位置(left, right)
          width: null, // 默认宽度，像素
        },
        {
          key: 'accountName',
          name: this.$t('dc:账号名称'),
          align: 'left',
          dataType: 'string',
          visible: true,
          sortable: false,
          fixed: null,
          width: 120,
        },
        {
          key: 'agency',
          name: this.$t('dc:代理商'),
          align: 'left',
          dataType: 'string',
          visible: true,
          sortable: false,
          fixed: null,
          width: 100,
        },
        {
          key: 'client',
          name: this.$t('dc:客户'),
          align: 'left',
          dataType: 'string',
          visible: true,
          sortable: false,
          fixed: null,
          width: 120,
        },
        {
          key: 'email',
          name: this.$t('dc:邮箱'),
          align: 'left',
          dataType: 'string',
          visible: true,
          sortable: false,
          fixed: null,
          width: 180,
        },
        {
          key: 'accountStatusName',
          name: this.$t('dc:账号状态'),
          align: 'left',
          dataType: 'string',
          visible: true,
          sortable: false,
          fixed: null,
          width: 100,
        },
      ]
    },
    columnOptions() {
      return this.columnConfig.filter((n) => n.visible)
    },
  },
  mounted() {
    this.initEvent()
  },
  methods: {
    async initEvent() {
      this.queryApi()
    },
    async queryApi() {
      this.loading = true
      const { status, data } = await $Api.user.getUserList(this.pageConfig)
      if (status === this.$RESPONSE_STATUS.SUCCESS) {
        this.tableData = data.list
        this.pageConfig.total = data.total
      }
      this.loading = false
    },
    accountEdit(data) {
      this.actionType = 0
      this.accountFromData = data
      this.showDialog = true
    },

    forbid(item) {
      this.actionType = 3
      this.accountData = item
      this.title = this.$t('dc:禁止')
      this.content = this.$t('dc:您确认要禁止该用户吗?')
      this.show4Dialog = true
    },
    recover(item) {
      this.actionType = 0
      this.accountData = item
      this.title = this.$t('dc:恢复')
      this.content = this.$t('dc:您确认要恢复该用户吗?')
      this.show4Dialog = true
    },
    reject(item) {
      this.actionType = 2
      this.accountData = item
      this.title = this.$t('dc:拒绝')
      this.content = this.$t('dc:您确认要拒绝该用户吗?')
      this.show4Dialog = true
    },
    showInformation(item) {
      this.informationData = item
      this.showInformationDialog = true
    },
    assignmentRole(item) {
      this.rowRolwData = item
      this.showAssignmentDialog = true
    },
    deleteRow(item) {
      this.actionType = 4
      this.accountData = item
      this.title = this.$t('dc:删除')
      this.content = this.$t('dc:您确认要删除该用户吗?')
      this.show4Dialog = true
    },
    async confrom() {
      switch (this.actionType) {
        case 0:
          this.accountStatus = 0
          break
        case 1:
          this.accountStatus = 1
          break
        case 2:
          this.accountStatus = 2
          break
        case 3:
          this.accountStatus = 3
          break
        case 4:
          this.accountStatus = 4
          break
        default:
          break
      }
      const { status, msg } = await $Api.user.updateStatus(
        this.accountData.userId,
        this.accountStatus,
      )
      if (status === this.$RESPONSE_STATUS.SUCCESS) {
        this.show4Dialog = false
        this.$message({
          showClose: true,
          message: msg || this.$t('dc:成功'),
          type: 'success',
        })
      } else {
        this.show4Dialog = false
      }
      this.queryApi()
    },
    toEmail(item) {
      this.title = this.$t('dc:邮件通知')
      this.senEmaiData = item
      this.sendEmailStatus = true
    },
  },
}
</script>

<style lang="scss">
.authority .poc-card-table .pcth-query-box .pcth-queryName {
  min-width: 0;
}
.authority .marginR24 {
  margin-right: 24px;
}
.authority .padding12 {
  padding: 0 12px;
}
.authority .disable {
  cursor: not-allowed;
  color: #ccc !important;
}
.authority .el-dialog .el-dialog__footer {
  // padding: 5px 12px;
  div {
    text-align: right;
  }
}
</style>
